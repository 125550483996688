import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'


const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Transistor Tech Tour - Lunch and learn from the leaders in technology',
      metaTags: [{
          name: 'description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        },
        {
          property: 'og:description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        }
      ]
    }
  },
  {
    path: '/vendors',
    name: 'Vendors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Vendors.vue'),
    meta: {
      title: 'Transistor Tech Tour - Vendors',
      metaTags: [{
          name: 'description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        },
        {
          property: 'og:description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        }
      ]
    }
  },
  {
    path: '/techtour2022',
    name: 'Techtour2022',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Techtour2022.vue'),
    meta: {
      title: 'Transistor Tech Tour - 2022',
      metaTags: [{
          name: 'description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        },
        {
          property: 'og:description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        }
      ]
    }
  },
  {
    path: '/techtour2023',
    name: 'Techtour2023',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/Techtour2023.vue'),
    meta: {
      title: 'Transistor Tech Tour - 2023',
      metaTags: [{
          name: 'description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        },
        {
          property: 'og:description',
          content: 'CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers.'
        }
      ]
    }
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return {
      top: 0
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})
/*
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});
*/
export default router
