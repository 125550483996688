<template>
  <div>
    <section class="countdown">
      <div class="ribbon">
        <div class="ribbon-inset">
          <div class="left-side"></div>
          <div class="logo">
            <img v-if="this.$i18n.locale == 'en'" src="../assets/tech-tour-2024-badge-eng.png" />
            <img class="fr-logo" v-if="this.$i18n.locale == 'fr'" src="../assets/tech-tour-2024-badge-fr.png" />
          </div>
          <div class="right-side"></div>
        </div>
      </div>
      <!-- <div class="background-blockout">
        <div></div>
        <div class="white"></div>
      </div> -->
    </section>

    <section class="presents">
      <div class="container">
        <div>
          <div>
            <img v-if="this.$i18n.locale == 'en'" class="promo-logo" src="../assets/transistor-tour-van-2024.jpg"
              alt="Tech van" />
            <img class="fr-logo promo-logo" v-if="this.$i18n.locale == 'fr'" src="../assets/transistor-tour-van-2024.jpg"
              alt="Tech van" />
          </div>
        </div>
        <div class="section-details">
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("presents") }}</span>
          <h2>{{ $t("first_headline_2024") }}</h2>
          <p>
            {{ $t("first_paragraph_2024") }}
          </p>
          <span class="label-dash">&nbsp;</span>
          <span class="section-label">&nbsp;</span>
        </div>
      </div>
    </section>

    <section class="vendors">
      <div class="container">
        <div>
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("participating_vendors") }}</span>
        </div>
        <div class="vendors-logos-2024">
          <router-link to="/vendors#cyberpower"><img src="../assets/logo-cyberpower.png" alt="Cyberpower logo" />
          </router-link>
          <router-link to="/vendors#aruba"><img src="../assets/logo-aruba.png" alt="Aruba logo" /></router-link>
          <router-link to="/vendors#opentext"><img src="../assets/logo-opentext.png" alt="OpenText logo" />
          </router-link>
          <router-link to="/vendors#lenovo"><img src="../assets/logo-lenovo.png" alt="Lenovo logo" /></router-link>
          <router-link to="/vendors#targus"><img src="../assets/logo-targus.png" alt="Targus logo" /></router-link>
          <router-link to="/vendors#watchguard"><img src="../assets/logo-watchguard.png" alt="Watchguard logo" />
          </router-link>
        </div>
        <div>
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("supporting_vendors") }}</span>
        </div>
        <div class="supporting-vendors-logos-2024">
          <a href="https://www.kingston.com/" target="_blank" rel="noopener noreferrer"><img
              src="../assets/kingston-logo.png" alt="Kingston logo" />
          </a>
          <a href="https://www.n-able.com/" target="_blank" rel="noopener noreferrer"><img
              src="../assets/n-able-logo.png" alt="N-Able Logo" />
          </a>
          <a href="https://www.pfu.ricoh.com/ca/en/" target="_blank" rel="noopener noreferrer"><img
              src="../assets/logo-pfu.png" alt="PFU scanners logo" />
          </a>
          <a href="https://www.teamviewer.com" target="_blank" rel="noopener noreferrer"><img
              src="../assets/team-viewer-logo.png" alt="TeamViewer logo" />
          </a>
        </div>
        <router-link to="/vendors" class="more-label">{{ $t("more_label") }} <span
            style="color: #D50032;">&#8250;</span></router-link>
      </div>
    </section>

    <section class="kick-off-2024">
      <div class="container">
        <div class="description">
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("kickoff_2024") }}</span>
          <h2>
            {{ $t("second_headline_2024") }}
          </h2>
          <img class="mobile-only" loading="lazy" src="../assets/tree-canada-1000.jpg" alt="" />
          <p>
            {{ $t("second_p1_2024") }}
          </p>
          <p>
            {{ $t("second_p2_2024") }}
          </p>
          <p>
            {{ $t("second_p3_2024") }}
          </p>
          <p v-html="$t('donate_combined_2024')"></p>
        </div>
      </div>
    </section>

    <section class="products">
      <div class="container">
        <div class="row">
          <span class="section-label">
            <span class="label-dash">&#8211;</span>{{ $t("what_youll_see") }}
          </span>

          <div class="container">
            <TechTourCarousel></TechTourCarousel>
          </div>
        </div>
      </div>
    </section>

    <section class="map-2024">
      <!-- <GoogleMap class="googlemap"></GoogleMap> -->
      <div class="overlay">
        <div class="container">
          <div class="description">
            <span class="label-dash">&#8211;</span>
            <span class="section-label">{{ $t("maps_cities_2024") }}</span>
            <h2>{{ $t("maps_headline_2024") }}</h2>
            <img class="mobile-only" loading="lazy" src="../assets/tour-map-2024.jpg" alt="" />
            <p>
              {{ $t("maps_paragraph_2024") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="social">
      <div class="container">
        <div>
          <img v-if="this.$i18n.locale == 'en'" class="promo-logo" src="../assets/tech-tour-2024-badge-eng.png"
            alt="Tech van" />
          <img class="fr-logo promo-logo" v-if="this.$i18n.locale == 'fr'" src="../assets/tech-tour-2024-badge-fr.png"
            alt="Tech van" />
        </div>
        <div class="description">
          <span class="label-dash">&#8211;</span>
          <span class="section-label">{{ $t("news_social") }}</span>
          <h2>{{ $t("news_social_headline_2024") }}</h2>
          <p>
            {{ $t("news_social_paragraph_1_2024") }}

            <a href="https://www.linkedin.com/in/transistor-tech-tour-6654b2224/"
              target="_BLANK">{{ $t("news_social_paragraph_2_2024") }}</a>
            {{ $t("news_social_paragraph_3_2024") }}
            <a href="https://www.instagram.com/transistortechtour/"
              target="_BLANK">{{ $t("news_social_paragraph_4_2024") }}</a>
            {{ $t("news_social_paragraph_5_2024") }}
            <br />
          </p>
          <div class="social-links">
            <a href="https://www.linkedin.com/in/transistor-tech-tour-6654b2224/" target="_BLANK">
              <img loading="lazy" src="../assets/social-linkedin.png" alt="LinkedIn logo" />
            </a>
            <a href="https://www.instagram.com/transistortechtour/" target="_BLANK">
              <img loading="lazy" src="../assets/social-instagram.png" alt="Instagram logo" />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section class="footer">
      <div class="container">
        <div class="branding">
          <img loading="lazy" src="../assets/cp-white.png" alt="CyberPower logo" />
        </div>
        <div>
          <p>{{ $t("footer_trademark_2024") }}</p>
          <ul class="copy-links">
            <!-- TODO - add anchor hrefs -->
            <!-- <li><a href="/company/terms">Terms &amp; Conditions</a></li>
            <li><a href="/company/privacy">Privacy Policy</a></li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
  // @ is an alias to /src
  // import GoogleMap from "@/components/Map.vue";
  //import TechTourCountDown from "@/components/TechTourCountDown.vue";
  import TechTourCarousel from "@/components/TechTourCarousel.vue";

  export default {
    name: "Home",
    mounted() {
      // disable instagram embed
      // let externalScript = document.createElement("script");
      // externalScript.setAttribute("src", "//www.instagram.com/embed.js");
      // document.head.appendChild(externalScript);
    },
    components: {
      // HelloWorld
      // GoogleMap,
      /* TechTourCountDown, */
      TechTourCarousel,
    },
    data() {
      return {};
    },
    methods: {
      setNumber(data) {
        this.thenumber = data;
      },
    },
  };
</script>


<style lang="scss" scoped>
  img {
    max-width: 100%;
  }

  //display these images only on mobile screens
  .mobile-only {
    margin-bottom: 20px;

    @media (min-width: 800px) {
      display: none;
    }
  }

  .background-blockout {
    width: 100%;
    height: 300px;

    display: block;
    position: relative;

    .white {
      background-color: rgba(255, 255, 255, 0.75);
    }

    @media (min-width: 800px) {
      height: 550px;
      position: absolute;
      height: 100%;
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
    }
  }

  .section-label {
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .countdown {
    min-height: 450px;

    .container {
      z-index: 6;
      position: relative;

      display: grid;

      background-color: rgba(255, 255, 255, 0.9);

      @media (min-width: 800px) {
        background-color: rgba(255, 255, 255, 0);
        padding-top: 0;
        padding-left: 135px;
        align-self: end;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }
    }
  }

  .presents {
    margin-top: -50px;

    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      @media (min-width: 992px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    .section-details {
      padding-bottom: 30px;

      h2 {
        margin-bottom: 32px;
        color: #000000;
        font-weight: 500;
        font-size: 2.625rem;
        line-height: 2.75rem;
      }

      @media (min-width: 600px) {
        grid-row-start: -2;
        grid-column-start: 2;
      }
    }
  }

  .footer {
    padding-top: 20px;
    background: #3c3c3c;
    font-size: 0.75em;

    .container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 40px;
    }

    .copy-links {
      margin-bottom: 0;
      margin-left: -30px;
      padding-top: 8px;
      padding-bottom: 20px;

      li {
        padding-right: 10px;
        display: inline-block;

        a {
          color: #fff;
          text-decoration: none;
        }
      }
    }

    img {
      width: 190px;
    }

    p {
      margin-bottom: 0;
      color: #ffffff;
    }

    @media (min-width: 800px) {
      .container {
        grid-template-columns: .75fr 1.25fr;
      }
    }
  }

  .home {
    display: grid;
    border: solid 1px;
    height: 100%;
    width: 100%;
    align-content: center;
    justify-content: center;

    img {
      max-width: 300px;
    }
  }

  .kick-off-2024 {

    h2 {
      margin-bottom: 32px;
      color: #000000;
      font-weight: 500;
      font-size: 2.625rem;
      line-height: 2.75rem;
    }

    .container {
      padding-bottom: 20px;
      display: grid;

      @media (min-width: 800px) {
        padding-bottom: 80px;
        grid-template-columns: 0.75fr 1.25fr;
      }
    }

    .description {
      a {
        color: #D50032;
        font-weight: 500;
      }
    }

    @media (min-width: 800px) {
      .container {
            padding-top: 80px;
      background-image: url("../assets/tree-canada-bg.jpg");
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
      }

      .mobile-only {
        display: none;
      }
    }
  }

  .instagram-media {
    background: #fff;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 1px;
    max-width: 540px;
    min-width: 326px;
    padding: 0;
    width: 99.375%;
    width: -webkit-calc(100% - 2px);
    width: calc(100% - 2px);
  }

  .map-2024 {
    background-color: none;
    position: relative;
    // min-height: 500px;

    h2 {
      margin-bottom: 32px;
      color: #000000;
      font-weight: 500;
      font-size: 2.625rem;
      line-height: 2.75rem;
    }

    @media (min-width: 1200px) {
      min-height: 450px;
    }

    .googlemap {
      position: relative;
      height: 500px;

      @media (min-width: 1200px) {
        position: absolute;
        // height: 800px;
      }

      z-index: 2;
      top: 0;
      right: 0;

      width: 50%;
    }

    .overlay {
      width: 100%;
      position: relative;
      z-index: 1;
      background-color: #EDEDED;

      @media (min-width: 992px) {
        background-image: url("../assets/tour-map-2024-bg.jpg");
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;

        .description {
          p {
            width: 80%;
          }
        }
      }

      @media (min-width: 1200px) {
        position: absolute;
        height: 450px;
      }
    }

    .container {
      padding-top: 30px;
      padding-bottom: 30px;
      display: grid;
      grid-template-columns: 1fr;

      @media (min-width: 1200px) {
        padding-top: 80px;
        padding-bottom: 50px;
        grid-template-columns: 1.25fr 1.75fr;
      }
    }
  }

  .products {
    padding-top: 80px;
    padding-bottom: 72px;
    background: linear-gradient(0deg, #F6F6F6 0%, #F6F6F6 100%);
  }

  .social {
    min-height: 450px;
    padding-top: 30px;
    padding-bottom: 30px;

    .promo-logo {
      width: 360px;
    }

    .fr-logo {
      width: 360px !important;
    }

    a {
      color: #D50032;
    }

    h2 {
      margin-bottom: 32px;
      color: #000000;
      font-weight: 500;
      font-size: 2.625rem;
      line-height: 2.75rem;
    }

    .container {
      display: grid;

      @media (min-width: 800px) {
        grid-template-columns: 1.4fr 1fr;
      }
    }

    .description {
      p {
        margin-bottom: 32px;
      }
    }

    .promo-logo {
      display: block;
      margin: 0 auto;
    }

    .social-links {
      img {
        margin-right: 16px;
        width: 58px;
      }
    }

    @media (min-width: 800px) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .vendors {
    min-height: 300px;
    padding-top: 60px !important;
    padding-bottom: 60px !important;
    background-color: #272727;
    color: #fff;

    .container {
      display: flex;
      flex-direction: column;
    }

    .more-label {
      align-self: flex-end;
      color: #fff;
      text-decoration: none;
      display: block;
      padding-bottom: 5px;
      font-weight: 700;
    }

    .supporting-vendors-logos-2024 {
      margin-bottom: 50px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-between;
      align-items: center;
      gap: 60px;

      img {
        align-self: middle;
        justify-self: center;
        width: 80%;
      }
    }

    @media (min-width: 600px) {
      .supporting-vendors-logos-2024 {
        grid-template-columns: repeat(3, auto);

        img {
          width: 100%;
        }
      }
    }

    @media (min-width: 992px) {
      .supporting-vendors-logos-2024 {
        grid-template-columns: repeat(4, auto);

        img {
          width: 75%;
        }
      }
    }

    .vendors-logos-2024 {
      margin-bottom: 195px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-between;
      align-items: center;
      gap: 60px;

      img {
        align-self: middle;
        justify-self: center;
        width: 80%;
      }
    }

    @media (min-width: 600px) {
      .vendors-logos-2024 {
        grid-template-columns: repeat(3, auto);

        img {
          width: 100%;
        }
      }

      .vendors-logos-2024>*:nth-child(3n) {
        justify-self: end;
        text-align: right;
      }
    }

    @media (min-width: 992px) {
      .vendors-logos-2024 {
        grid-template-columns: repeat(3, auto);

        img {
          width: 75%;
        }
      }
    }
  }

  //vendor carousel
  .carousel {
    width: 100%;
  }

  .slide-box {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .slide-box img {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .slide-box img {
      -ms-flex: 0 0 33.3333%;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }

  @media (min-width: 992px) {
    .slide-box img {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  .carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 0.5rem;
  }

  .ttour-enter-from {
    opacity: 0;
  }

  .ttour-enter-active {
    transition: all 1s ease-out;
    transition-delay: 3s;
  }

  .ttour-leave-to {
    opacity: 0;
  }

  .ttour-leave-active {
    transition: all 1s ease-in;
    transition-delay: 3s;
  }

  .language-bar {
    height: 60px;

    .language-toggle {
      position: fixed;
      right: 75px;
      top: 0;
      padding: 0.5em 0.5em;
      text-align: center;
      z-index: 5;
      cursor: pointer;

      @media (min-width: 500px) {
        padding: 0.5em 2em;
      }

      span {
        background-color: #cccccc;
        padding: 10px;
        display: inline-block;
        border-radius: 20px;
        box-shadow: inset 0px 2px 4px 2px rgba(0, 0, 0, 0.5);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
      }
    }
  }
</style>
<!-- IL8N example: <p>{{ $t("message") }}</p> -->
