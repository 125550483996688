<script>
import { computed, ref } from "vue";
import { useKeenSlider } from "keen-slider/vue"; // import from 'keen-slider/vue.es' to get an ES module
import "keen-slider/keen-slider.min.css";
export default {
  setup() {
    const current = ref(0);
    const [container, slider] = useKeenSlider({
      initial: current.value,
      loop: true,
      slideChanged: (s) => {
        current.value = s.track.details.rel;
      },
      slides: {
        perView: 4,
        spacing: 15,
      },
      breakpoints: {
        "(min-width: 100px)": {
          slides: { perView: 1, spacing: 1 },
        },
        "(min-width: 400px)": {
          slides: { perView: 2, spacing: 5 },
        },
        "(min-width: 1000px)": {
          slides: { perView: 4, spacing: 10 },
        },
      },
    });
    const dotHelper = computed(() =>
      slider.value
        ? [...Array(slider.value.track.details.slides.length).keys()]
        : []
    );
    return { container, dotHelper, slider, current };
  },
  methods: {
    moveDot(id) {
      this.slider.moveToIdx(id);
    },
  },
};
</script>

<template>
  <div>
    <div ref="container" class="keen-slider">
      <div class="keen-slider__slide number-slide1">
        <router-link to="/vendors#cyberpower"><img
            src="../assets/8554_cpu_web_landingpage_vendor_promo_cyberpower_v1.png" alt="CyberPower products" />
        </router-link>
      </div>
      <div class="keen-slider__slide number-slide2">
        <router-link to="/vendors#aruba"><img src="../assets/product-aruba.png" alt="Aruba products" /></router-link>
      </div>
      <div class="keen-slider__slide number-slide4">
        <router-link to="/vendors#opentext"><img src="../assets/product-opentext.png" alt="Opentext" />
        </router-link>
      </div>
      <div class="keen-slider__slide number-slide3">
        <router-link to="/vendors#lenovo"><img src="../assets/product-lenovo.jpeg" alt="Lenovo products" />
        </router-link>
      </div>
      <div class="keen-slider__slide number-slide5">
        <router-link to="/vendors#targus"><img src="../assets/product-targus.png" alt="Targus products" /></router-link>
      </div>
      <div class="keen-slider__slide number-slide6">
        <router-link to="/vendors#watchguard"><img src="../assets/product-watchguard.png" alt="Watchguard products" />
        </router-link>
      </div>

    </div>
    <div v-if="slider" class="dots">
      <button v-for="(_slide, idx) in dotHelper" @click="moveDot(idx)" :class="{ dot: true, active: current === idx }"
        :key="idx"></button>
    </div>
  </div>
</template>

<style lang="scss">
.dots {
  button {
    background-color: #cecece;
  }
}
[class^="number-slide"],
[class*=" number-slide"] {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 269px;
  max-height: 100vh;
  img {
    border: 2px solid #cecece;
  }
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}
.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}
.dot:focus {
  outline: none;
}
.dot.active {
  background: #d50032;
}
</style>
