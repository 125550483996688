export default {
  "previous_tour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LAUNCH THE 2022 TECH TOUR WEBSITE"])},
  "l_ribbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor"])},
  "r_ribbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Van"])},
  "presents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower Presents"])},
  "first_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch and learn from the leaders in tech"])},
  "first_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers. Each outdoor event will include free food, beverages, and promotional items."])},
  "first_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch and learn from the leaders in tech"])},
  "first_paragraph_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, along with other leading tech companies will be traveling across Canada this summer to connect with customers and share their latest tech solutions and offers. Each event will include free food, beverages, and promotional items."])},
  "first_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The final chapter of the Transistor Tech Tour"])},
  "first_paragraph_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join CyberPower, along with other industry leaders, during the final chapter of the Transistor Tech Tour. The tour will make it's way across Canada this summer to connect with customers and share their latest tech solutions and offers. Each event will include free food, beverages, and swag."])},
  "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COUNTDOWN TO NEXT STOP"])},
  "visit_vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Vendor"])},
  "participating_vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIN VENDORS"])},
  "supporting_vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUPPORTING VENDORS"])},
  "more_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More About Vendors"])},
  "kickoff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KICKOFF MAY 25TH, 2022"])},
  "second_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're hitting the road to help you down the path to success"])},
  "second_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, along with other tech companies, will visit over 30 cities across Canada with 62 special events to support Canadian IT resellers."])},
  "second_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower will be joined by Acer, Aruba, Buffalo, Cradlepoint, Ergotron, Fujitsu, Kaspersky, Lenovo, Logitech, Microsoft 365, Promethean, StarTech, Targus, Vendasta, and WatchGuard."])},
  "second_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower is proud to be raising funds in support of The Terry Fox Foundation and its mission to fund life-changing cancer research. "])},
  "donate_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terry Fox Foundation"])},
  "donate_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://run.terryfox.ca/3044/page/15694"])},
  "donate_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the "])},
  "donate_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and how to donate."])},
  "donate_combined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the <a href='https://run.terryfox.ca/3044/page/15694' target='_blank'>Terry Fox Foundation</a> and how to donate."])},
  "kickoff_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KICKOFF June 20, 2023"])},
  "second_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Transistor Tech Tour is on the move"])},
  "second_p1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower, along with other tech companies, will be making stops across Canada to meet with Canadian IT resellers."])},
  "second_p2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" CyberPower will be joined by Aruba Instant-On, PFU, a Ricoh Company, Lenovo, Targus, WatchGuard and OpenText."])},
  "second_p3_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition, CyberPower will be raising funds in support of the Trans Canada Trail and its mission to fund Canada’s national trail, the longest network of multi-use recreational trails in the world."])},
  "donate_link_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trans Canada Trail"])},
  "donate_url_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.canadahelps.org/en/pages/transistor-tech-tour-2023/"])},
  "donate_1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the "])},
  "donate_2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and how to donate."])},
  "donate_combined_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the <a href='https://www.canadahelps.org/en/pages/transistor-tech-tour-2023/' target='_blank'>Trans Canada Trail</a> and how to donate."])},
  "kickoff_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KICKOFF MAY 15TH, 2024"])},
  "second_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Transistor Tech Tour is underway"])},
  "second_p1_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't miss the your chance to join the final Transistor Tech Tour."])},
  "second_p2_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower is joined by Aruba Instant-On, Lenovo, WatchGuard, OpenText, Targus, Kingston, PFU a Ricoh Company, N-Able, and TeamViewer."])},
  "second_p3_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This summer, CyberPower will be raising funds for Tree Canada, dedicated to planting and nurturing trees in rural and urban environments, in every province across Canada."])},
  "donate_link_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trans Canada Trail"])},
  "donate_url_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.justgiving.com/page/noah-jacobs-1704301202610"])},
  "donate_1_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the "])},
  "donate_2_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and how to donate."])},
  "donate_combined_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more about the <a href='https://www.justgiving.com/page/noah-jacobs-1704301202610' target='_blank'>Tree Canada</a> and how to donate."])},
  "what_youll_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT YOU'LL SEE"])},
  "maps_cities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPS AND CITIES"])},
  "maps_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tech Tour"])},
  "maps_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Transistor Tech Tour kicks off in St. John's NL, Canada on May 25th. Each tour event will take place outdoors and will feature new products or offers from vendors, plus free food, beverages and promotional items. Look for Transistor Tech Tour van in your town soon!"])},
  "maps_cities_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPS AND CITIES"])},
  "maps_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tech Tour"])},
  "maps_paragraph_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year's Transistor Tech Tour kicks off in Montréal on Tuesday, June 20th. Each event will feature new products or offers from vendors, plus free food, beverages, and promotional items. Join us when we visit your town!"])},
  "maps_cities_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPS & CITIES"])},
  "maps_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transistor Tech Tour"])},
  "maps_paragraph_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This year's Transistor Tech Tour kicks off Wednesday, May 15th. Each event will feature new products or offers from vendors, plus free food, beverages, and promotional items. Join us when we visit your town!"])},
  "wrap_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["THAT'S A WRAP!"])},
  "wrap_subhead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for joining us in 2023!"])},
  "wrap_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just in case you missed the trip, here is a quick recap of the exciting road that brought us together and the fantastic products that set us apart. Until next time, be sure to follow us on "])},
  "wrap_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "wrap_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "wrap_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "wrap_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" for CyberPower's 2024 Tech Tour dates and times. "])},
  "news_social": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEWS AND SOCIAL"])},
  "news_social_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep up with Transistor the Van"])},
  "news_social_paragraph_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Transistor the Van on "])},
  "news_social_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "news_social_paragraph_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to stay up to date with its journey across the country. Stay connected with the Tech Tour to keep up with featured technology showcases, new products, and special offers."])},
  "news_social_headline_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep up with the Transistor Tech Tour"])},
  "news_social_paragraph_1_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the Transistor Tech Tour on "])},
  "news_social_paragraph_2_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "news_social_paragraph_4_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5_2023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and keep up with featured technology showcases, new products, and special offers."])},
  "news_social_headline_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep up with the Transistor Tech Tour"])},
  "news_social_paragraph_1_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the Transistor Tech Tour on "])},
  "news_social_paragraph_2_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn"])},
  "news_social_paragraph_3_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
  "news_social_paragraph_4_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
  "news_social_paragraph_5_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and keep up with featured technology showcases, new products, and special offers. Be sure to use the hashtag #TechTour2024 when we show up to your town!"])},
  "news_social_cta_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow Us"])},
  "news_social_video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WATCH THE 2022 TRANSITOR THE VAN TECH TOUR RECAP VIDEO"])},
  "back_to_main_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to main page"])},
  "ultimate_ally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ultimate ally in Power"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hours"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minutes"])},
  "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seconds"])},
  "vendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor"])},
  "footer_trademark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2023 Cyber Power Systems (USA), Inc. CyberPower is a registered trademark and brand of Cyber Power Systems (USA), Inc. All rights reserved."])},
  "footer_trademark_2024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["©2024 Cyber Power Systems (USA), Inc. CyberPower is a registered trademark and brand of Cyber Power Systems (USA), Inc. All rights reserved."])},
  "brands": {
    "Aruba": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check \"reliable, secure, fast Wi-Fi\" off your to-do list. Set up your network in minutes with our mobile app and enjoy fast speeds inside and outside. Manage your business network from anywhere, easily. Relax knowing your business, employees, and customers are protected by the latest security automatically—at no extra cost to you."])},
    "CyberPower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CyberPower manufactures professional-grade power protection equipment. These proven, trusted power management solutions meet the most critical requirements from SMB, corporate offices, healthcare, government, and education facilities. Your ultimate ally in power!"])},
    "Lenovo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lenovo is a US$70 billion revenue global technology powerhouse, ranked #171 in the Fortune Global 500, employing 82,000 people around the world, and serving millions of customers every day in 180 markets. Focused on a bold vision to deliver smarter technology for all, Lenovo has built on its success as the world's largest PC company by further expanding into key growth areas including server, storage, mobile, solutions and services. This transformation together with Lenovo's world-changing innovation is building a more inclusive, trustworthy, and sustainable digital society for everyone, everywhere."])},
    "OpenText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opentext is a proud Canadian company, Employing over 25,000 employees globally. Through strategic acquisitions such as Carbonite, Webroot, Zix - OpenText Cybersecurity provides comprehensive security solutions for companies and partners of all sizes. From prevention, detection and response to recovery, investigation and compliance, our unified end-to-end platform helps customers build cyber resilience via a holistic security portfolio."])},
    "PFU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICOH enable organizations and individuals to improve productivity, save time and money, organize and structure your documents, share information and is often the first element of larger digital transformation projects."])},
    "Targus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more than thirty-five years, Targus has been implementing purposeful solutions to provide you with the tools you need to succeed. Our global reach, and commitment to quality ensures we have the skills and experience to meet your performance, style, and protection needs."])},
    "WatchGuard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WatchGuard provides industry-leading network and endpoint security, secure Wi-Fi, multi-factor authentication, and network intelligence products and services, enabling more than 250,000 small and midsize enterprises from around the globe to protect their most important assets including over 10 million endpoints."])}
  }
}